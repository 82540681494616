<template>
  <navbar />
  <router-view/>
</template>

<script>
import navbar from "@/components/Navbar.vue";
export default {
  name: 'App',
  components: {
    navbar
  }
}
</script>


<style lang="less">

html, body {
  padding: 0;
  margin: 0;
  background-color: black;
}

#app {

  font-family: area-normal,sans-serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
