<template>
  <nav id="main-nav">

    <img alt="Lunega Logo" src="../assets/lunega_bg.png">
    <span>Lunega Custom Order System</span>

  </nav>
</template>

<script>

export default {
  name: 'Navbar',
  components: {}
}
</script>

<style lang="less" scoped>
  nav {

    background-color: black;
    display: flex;
    align-items: center;
    gap: 20px;

    justify-content: left;

    padding: 15px;
    position: sticky;

    a, span {

      display: block;
      color: white;
      text-decoration: none;
      border-bottom: solid 1px transparent;
      transition: all ease-in-out 0.1s;

      //&:hover {
      //  border-bottom: solid 1px #e95728;
      //  color: #00aced;
      //}
    }

    img {
      height: 35px;
    }

  }
</style>