<template>

  <div id="progress" v-if="progress">

    <div class="progress-container">
      <br>
      <h1>Rendering...</h1>
      <progress id="renderprogress" :max="layers.length" :value="progress"/>
    </div>

  </div>

  <div id="container">

    <div id="preview" :style="bg"/>

    <form>
      <fieldset>

        <h1>
          Model<br>
          <span>Which is best for you?</span>
        </h1>

        <div class="radio-button" :class="{ active: model === 'bunny'}" @click="model='bunny'">
          <h2>Rabbit</h2>
          <div class="subtext">Oryctolagus cuniculus</div>
        </div>
        <div class="radio-button" :class="{ active: model === 'dog'}" @click="model='dog'">
          <h2>Dog</h2>
          <div class="subtext">Canis lupus familiaris</div>
        </div>
        <div class="radio-button" :class="{ active: model === 'cat'}" @click="model='cat'">
          <h2>Cat</h2>
          <div class="subtext">Felis catus</div>
        </div>

      </fieldset>

      <fieldset>
        <TransitionGroup>
          <div v-if="model === 'cat' ">
            <h1>Customization</h1>
            <p>We don't offer any customizations trough online purchases for this model. Please choose another model if you need to customize your unit. </p>
            <p>All artwork on this page was made by <a href="https://twitter.com/Hypn0Drama">HypnoDrama</a>. Please give them a follow for their aswesome work!</p>
            <h1>Place order</h1>
            <button type="button" @click.stop="download('cat')">Confirm order</button>
          </div>

          <div v-if="model === 'dog' ">
            <h1>Customization</h1>
            <p>We don't offer any customizations trough online purchases for this model. Please choose another model if you need to customize your unit. </p>
            <p>All artwork on this page was made by <a href="https://twitter.com/Hypn0Drama">HypnoDrama</a>. Please give them a follow for their aswesome work!</p>
            <h1>Place order</h1>
            <button type="button" @click.stop="download('dog')">Confirm order</button>
          </div>

          <div v-if="model === 'bunny'">

            <br><br>
            <h1>
              Theme<br>
              <span>Pick your favorite.</span></h1>

            <div class="radio-button" :class="{ active: color === 'classic'}" @click="color='classic'">
              <h2>Classic</h2>
              <div class="subtext">The classic colors you know and love</div>
            </div>
            <div class="radio-button" :class="{ active: color === 'light'}" @click="color='light'">
              <h2>Light</h2>
              <div class="subtext">Something to brighten up your day</div>
            </div>
            <div class="radio-button" :class="{ active: color === 'dark'}" @click="color='dark'">
              <h2>Dark</h2>
              <div class="subtext">Get lost in the deepest of blues</div>
            </div>

            <br><br>
            <h1>Collar<br>
              <span>Come to the bright side.</span></h1>

            <div class="radio-button" :class="{ active: collar === 'dark'}" @click="collar='dark'">
              <h2>Dark</h2>
              <div class="subtext">A little splash of darkness</div>
            </div>
            <div class="radio-button" :class="{ active: collar === 'light'}" @click="collar='light'">
              <h2>Light</h2>
              <div class="subtext">A little splash of light</div>
            </div>

            <br><br>
            <h1>Clothing<br>
              <span>Make it your own.</span></h1>

            <div class="radio-button" :class="{ active: shirt === 'none'}" @click="shirt='none'">
              <h2>Nothing</h2>
              <div class="subtext">Bring your own damn clothes.</div>
            </div>
            <div class="radio-button" :class="{ active: shirt === 'classic'}" @click="shirt='classic'">
              <h2>"You are a ⭐" Shirt</h2>
              <div class="subtext">It's a classic at this point.</div>
            </div>
            <div class="radio-button" :class="{ active: shirt === 'dva'}" @click="shirt='dva'">
              <h2>D.Va "Overwatch"</h2>
              <div class="subtext">Don't sue me ;3c</div>
            </div>

            <br><br>
            <div class="radio-button" :class="{ active: !jacket}" @click="jacket=false">
              <h2>No Jacket</h2>
            </div>
            <div class="radio-button" :class="{ active: jacket === true}" @click="jacket=true">
              <h2>Generic Jacket</h2>
              <div class="subtext">If it's cold, you're cold</div>
            </div>
            <div class="radio-button" :class="{ active: jacket === 'branded'}" @click="jacket='branded'">
              <h2>Lunega™ branded Jacket</h2>
              <div class="subtext">Show off that swag</div>
            </div>

            <br><br>
            <h1>Certifications<br>
              <span>Trust us, They're optional.</span></h1>

            <div class="radio-button" :class="{ active: certifications === false}" @click="certifications=false">
              <h2>Nothing</h2>
            </div>
            <div class="radio-button" :class="{ active: certifications === true}" @click="certifications=true">
              <h2>FCC & CE<sup>*</sup></h2>
              <div class="subtext"><sub style="opacity: 5%;">*. No actual certification included.</sub></div>
            </div>

            <br><br>
            <h1>Finalize</h1>
            <p>When you are done customizing your unit, You can click the download button below to confirm your order.</p>
            <p>All artwork on this page was made by <a href="https://twitter.com/Hypn0Drama">HypnoDrama</a>. Please give them a follow for their aswesome work!</p>
            <button type="button" @click.stop="download('bunny')">Confirm order</button>

          </div>


        </TransitionGroup>
      </fieldset>

    </form>
  </div>

</template>

<script>

import baseimg from "@/assets/lunega_bg.png";
import ref_dog from "@/assets/ref_dog.png";
import ref_cat from "@/assets/ref_cat.png";

import layer_underbody from "@/assets/bunny/0005s_0007_Underbody.png"
import layer_underbody_lines from "@/assets/bunny/0006s_0000_Lines-Underbody.png"

import layer_lines_new from "@/assets/bunny/0005s_0002_Lines-(new-pattern).png"
import layer_lines_old from "@/assets/bunny/0005s_0001_Lines-(old-pattern).png"

import layer_color_classic from "@/assets/bunny/0005s_0004_Classic-pattern.png"
import layer_color_light from "@/assets/bunny/0005s_0006_Light-pattern.png"
import layer_color_dark from "@/assets/bunny/0005s_0005_Dark-pattern.png"

import layer_collar_light from "@/assets/bunny/0005s_0003_White-collar.png"

import layer_shirt_star_lines from "@/assets/bunny/0003s_0000_Lines-star.png"
import layer_shirt_star_color from "@/assets/bunny/0003s_0001_Colors-star.png"
import layer_shirt_dva_lines from "@/assets/bunny/0004s_0000_Lines-dva.png"
import layer_shirt_dva_color from "@/assets/bunny/0004s_0001_Color-dva.png"

import layer_jacket_lines_noshirt from "@/assets/bunny/0001s_0000_Lines-jacket.png"
import layer_jacket_lines from "@/assets/bunny/0002s_0000_Lines-Jacket-s.png"

import layer_jacket_noshirt from "@/assets/bunny/0001s_0002_Colors-jacket.png"
import layer_jacket from "@/assets/bunny/0002s_0002_Color-jacket-s.png"

import layer_jacket_logo from "@/assets/bunny/0002s_0001_Logo.png"

import layer_fcc from "@/assets/bunny/0005s_0000_FCC-logo.png"

import layer_items_logo from "@/assets/bunny/0000s_0000_Logo.png"
import layer_items_lines from "@/assets/bunny/0000s_0001_Lines-Extra.png"
import layer_items_bag_pattern2 from "@/assets/bunny/0000s_0002_Bag-pattern-2.png"
import layer_items_dva_logo from "@/assets/bunny/0000s_0004_Dva-logo.png"
import layer_items_bag_patterns from "@/assets/bunny/0000s_0003_Bag-pattern.png"
import layer_items_colors_extra from "@/assets/bunny/0000s_0005_Colors-extra.png"

export default {
  name: 'HomeView',
  components: {},
  data: ()=>{
    return {
      model: null,
      color: 'classic',
      collar: 'dark',
      shirt: 'none',
      jacket: false,
      certifications: false,
      progress: false,
    }
  },
  methods: {
    async download(asset) {

      const el = document.createElement('a');

      switch (asset) {
        case "cat":
          el.href = ref_cat;
          break;
        case "dog":
          el.href = ref_dog;
          break;
        case "bunny":
          el.href = await this.generate();
          break;
      }

      console.log(el.href, asset);
      el.download = "sterr_reference_" + asset;
      el.click();

      console.log(el);

    },

    getImage(url){
      return new Promise((Resolve, Reject)=>{
        let img = new Image();
        img.onload = function(){
          Resolve(img);
        };
        img.src = url;
      })
    },

    async generate() {

      const cv = document.createElement("canvas");
      cv.width = 3480
      cv.height = 2160

      this.progress = 1;

      if (cv.getContext) {

        const ctx = cv.getContext("2d");

        for(let layer of this.layers.reverse()) {
          this.progress++;
          const img = await this.getImage(layer);
          console.log('Rendering layer', img.src);
          ctx.drawImage(img, 0, 0, img.width, img.height)
        }

        this.progress = false;
        return cv.toDataURL('png');

      }
    }
  },
  computed: {

    bg(){

      let images = [];
      let positions = [];
      let repeat = [];
      for(let layer of this.layers){
        images.push(`url("${layer}")`);
      }

      return `background-position: center; background-size: contain; background-repeat: no-repeat; background-image: ${images.join(',')};`;

    },

    layers(){

      let layers = [];

      if(this.model === null){ layers.push(baseimg) }
      if(this.model === 'dog'){ layers.push(ref_dog) }
      if(this.model === 'cat'){ layers.push(ref_cat) }
      if(this.model === 'bunny'){

        if(this.jacket){

          if(this.jacket === 'branded'){
            layers.push(layer_items_logo);
            layers.push(layer_jacket_logo);
          }

          if(this.shirt !== 'none'){
            layers.push(layer_jacket_lines);
            layers.push(layer_jacket);
          }else{
            layers.push(layer_jacket_lines_noshirt);
            layers.push(layer_jacket_noshirt);
          }
        }

        switch(this.shirt){
          case "classic":
            layers.push(layer_shirt_star_lines);
            layers.push(layer_shirt_star_color);
            break;
          case "dva":
            layers.push(layer_shirt_dva_lines);
            layers.push(layer_shirt_dva_color);
            break;
        }

        if(this.certifications){
          layers.push(layer_fcc)
        }

        if(this.collar === 'light'){
          layers.push(layer_collar_light);
        }

        layers.push(this.color !== 'light' ? layer_lines_old : layer_lines_new);

        switch(this.color){
          case "classic": layers.push(layer_color_classic); break;
          case "light": layers.push(layer_color_light); break;
          case "dark": layers.push(layer_color_dark); break;
        }

        layers.push(layer_underbody);

        // side stuff
        layers.push(layer_items_lines);
        layers.push(layer_items_bag_pattern2);
        layers.push(layer_items_dva_logo);
        layers.push(layer_items_bag_patterns);
        layers.push(layer_items_colors_extra);

      }

      return layers;

    }


  }
}
</script>

<style lang="less" scoped>

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
    position: absolute;
  }

  #container {
    display: grid;
    padding: 50px;
    gap: 25px;
    grid-template-columns: auto 30vw;
    grid-template-rows: 100%;
    height: calc(100vh - 65px - 100px);
  }

  @media only screen and (max-width: 600px) {
    #container {
      display: grid;
      padding: 50px;
      gap: 25px;
      grid-template-columns: 100%;
      grid-template-rows: 25vh auto;
      height: calc(100vh - 65px - 100px);
    }
  }

  fieldset {
    outline: none;
    border: none;
  }

  .radio-button {

    width: auto;
    height: 50px;

    margin: 10px 0px;

    border-radius: 10px;
    border: solid 1px white;

    text-align: left;
    padding: 15px;

    transition: border-color ease 0.2s;

    &.active {
      border: solid 1px #00abec;
      background-color: rgba(255,255,255,0.1);
    }

  }

  #preview, #preview_temp {

    border-radius: 25px;
    background-color: rgba(255,255,255,0.05);
    border: solid 2px rgba(255,255,255,0.1);

  }

  form {

    color: white;
    text-align: left;

    height: 100%;
    overflow-y: scroll;
  }

  h1 {

    font-size: 1.5rem;

    span {
      opacity: 0.2;
      display: inline-block;
    }
  }

  h2 { font-size: 1rem; margin: 5px 0;}

  #progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.91);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress-container {
    width: 80vw;
    max-width: 400px;
    height: 100vh;
    max-height: 200px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1a1a1b;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
